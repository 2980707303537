import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  SafeAreaView,
  StatusBar,
  Image,
} from "react-native";
import Svg, {
  Defs,
  LinearGradient, Rect, Stop,
} from 'react-native-svg';



import { benifitLogo } from "./assets";

import  { widthPercentageToDP, heightPercentageToDP, verticalScale } from "../../../components/src/Scale";
// Customizable Area End

import LandingPageController, {
  Props,
  configJSON
} from "./LandingPageController";

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      <SafeAreaView style={styles.mainContainer}>
        {/* Customizable Area Start */}
        <StatusBar hidden />
        <Svg style={styles.svgContainer}>
          <Defs>
            <LinearGradient
              id="gradient"
              x1="50%"
              y1="0%"
              x2="100%"
              y2="100%">
              <Stop offset="0%" stopColor="#27467A" />
              <Stop offset="100%" stopColor="#000836" />
            </LinearGradient>
          </Defs>
          <Rect x="0"
            y="0"
            width={widthPercentageToDP(100)}
            height={heightPercentageToDP(100)}
            fill="url(#gradient)" />
        </Svg>
        <Image source={benifitLogo} style={styles.logo} />
        {/* Customizable Area End */}
      </SafeAreaView>
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  landingPageView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  landingPageText: {
    fontSize: 42,
    letterSpacing: 2,
    fontWeight: "bold",
    color: "#323441",
    marginTop: 15
  },
  mainContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff"
  },
  container: {
    height: heightPercentageToDP(100),
    width: widthPercentageToDP(100),
    justifyContent: 'center',
    alignItems: 'center'
  },
  image: {
    width: widthPercentageToDP(80),
    alignSelf: "center",
    resizeMode: 'contain',
    marginLeft: verticalScale(30),
  },
  logo_container: {
    height: heightPercentageToDP(50),
    width:widthPercentageToDP(100),
    justifyContent: 'center',
    alignItems: "center",
  


  },
  description_container: {
  
    height: heightPercentageToDP(25),
    padding: verticalScale(10),
    flexDirection: 'column',
  },
  description: {
    color: '#ffffff',
    textAlign: 'center',
    fontSize: verticalScale(16),
    fontWeight: '300'
  },
  title: {
    color: '#ffffff',
    textAlign: 'center',
    fontSize: verticalScale(28),
    fontWeight: '600',
    paddingBottom: 40

  },
  next_button: {
    height: heightPercentageToDP(8),
    width: widthPercentageToDP(80),
    backgroundColor: '#00316F',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  
  },
  button_text: {
    color: "#ffffff",
    fontWeight: '500'
  },
  logo:{
    width: verticalScale(120),
    resizeMode:'contain'
  },
  svgContainer:{
    position: 'absolute',
    width:"100%",
    height: "100%",
  }

});
// Customizable Area End

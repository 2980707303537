import React from "react";

// Customizable Area Start
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  TouchableWithoutFeedback,
  KeyboardAvoidingView,
  SafeAreaView,
  StatusBar
} from "react-native";
import CustomLoader from "../../../components/src/CustomLoader";
import { configJSON } from "./EmailAccountRegistrationController";
import Icon from 'react-native-vector-icons/Feather';
import { verticalScale, heightPercentageToDP, widthPercentageToDP } from "../../../components/src/Scale";

import CustomInput from "../../../components/src/CommonInput";
import { Arrowleft } from "./assets";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  
  // Customizable Area Start
  phoneNumberInput = () =>{
    return(
      <>
      <CustomInput
          testID="phoneNumber"
          placeHolder={configJSON.phoneNumber}
          value={this.state.phone} handleInput={this.handlePhoneNumber}
          keyboardType="default"
          valid={this.state.isValidatePhoneNumber}
          errorMsg={
            !this.state.isValidatePhoneNumber && this.state.mobileErrMessage.length < 1 && configJSON.errorMsgPnoneNumber
          }
        />
        {this.state.mobileErrMessage.length > 0 &&
          <View style={{ alignSelf: 'center', width: widthPercentageToDP(90) }}>
            <Text style={styles.errorMsg}>{this.state.mobileErrMessage}</Text>
          </View>
        }
      </>
    )
  }
  companyFacilityTaxIdInputs = () =>{
    return(
      <>
        <CustomInput testID="companyFacility" placeHolder={configJSON.placeHolderCompanyFacility} value={this.state.companyFacilityName} handleInput={this.handleCompanyFacilityName} errorMsg={!this.state.isValidateCompanyFacilityName && configJSON.errorCompanyFacilityName} valid={this.state.isValidateCompanyFacilityName} />
        <CustomInput testID="taxId" placeHolder={configJSON.PlaceHolderTaxId} value={this.state.taxId} handleInput={this.handleTaxId} errorMsg={!this.state.isValidateTaxId && configJSON.errorTaxId} valid={this.state.isValidateTaxId} />
      </>
    )
  }
  emailpasswordConfirmPAsswordInputs = () =>{
    return(
      <>
        <CustomInput
          testID="email"
          placeHolder={configJSON.placeHolderEmail}
          value={this.state.email} handleInput={this.handleEmail}
          valid={this.state.isValidEmail}
          errorMsg={
            !this.state.isValidEmail && this.state.emailErrMessage.length < 1 && configJSON.errorEmailNotValid
          }
        />
        {this.state.emailErrMessage.length > 0 &&
          <View style={{ alignSelf: 'center', width: widthPercentageToDP(90) }}>
            <Text style={styles.errorMsg}>{this.state.emailErrMessage}</Text>
          </View>
        }
        <CustomInput testID="password" placeHolder={configJSON.placeHolderPassword} value={this.state.password} handleInput={this.handlePassword} icon={true} showHidePassword={this.showHidePassword} iconName={this.state.showHidePassword ? "eye-off" : "eye"} errorMsg={!this.state.isValidPassword && configJSON.warningInfo} passwordVisibility={this.state.showHidePassword} valid={this.state.isValidPassword} />
        <CustomInput testID="confirmPassword"
          placeHolder={configJSON.placeHolderConfirmPassword}
          value={this.state.confirmPassword}
          handleInput={this.handleConfirmPassword}
          icon={true} showHidePassword={this.showHideConfirmPassword}
          iconName={this.state.showHideConfirmPassword ? "eye-off" : "eye"}
          passwordVisibility={this.state.showHideConfirmPassword}
          valid={this.state.isValidConfirmPassword} />
        {
          !this.state.isValidConfirmPassword && String(this.state.confirmPassword).length > 1 &&
          <View style={{ alignSelf: 'center', width: widthPercentageToDP(90) }}>
            <Text style={styles.errorMsg}>{configJSON.confirmPasswordInfo}</Text>
          </View>
        }
      </>
    )
  }
  commonInputs = () =>{
    return(
      <>
        {
          this.emailpasswordConfirmPAsswordInputs()
        }
        {
          this.phoneNumberInput()
        }
      </>
    )
  }
  renderEmployerHr = () => {
    return (
      <>
        {
          this.state.userType === "Employer (HR)" &&
          <>
            <CustomInput testID="firstName" placeHolder={configJSON.labelFirstName} value={this.state.firstName} handleInput={this.handleFirstName} errorMsg={!this.state.isValidateFname && configJSON.errorFname} valid={this.state.isValidateFname} />
            <CustomInput testID="lastName" placeHolder={configJSON.lastName} value={this.state.lastName} handleInput={this.handleLastName} errorMsg={!this.state.isValidateLname && configJSON.errorLname} valid={this.state.isValidateLname} />
            <CustomInput testID="address" placeHolder={configJSON.address} value={this.state.address} handleInput={this.handleAddress} errorMsg={!this.state.isValidateAddress && configJSON.errorAddress} valid={this.state.isValidateAddress} />
           {
            this.phoneNumberInput()
           }
            {
              this.emailpasswordConfirmPAsswordInputs()
            }
            {
              this.companyFacilityTaxIdInputs()
            }
          </>
        }
      </>
    )
  }
  renderEmployeeMember = () => {
    return (
      <>
        {
          this.state.userType === "Employee (Member)" &&
          <>
            {
              this.commonInputs()
            }
            <CustomInput testID="companyFacility" placeHolder={configJSON.placeHolderCompanyFacility} value={this.state.companyFacilityName} handleInput={this.handleCompanyFacilityName} errorMsg={!this.state.isValidateCompanyFacilityName && configJSON.errorCompanyFacilityName} valid={this.state.isValidateCompanyFacilityName} />
            <CustomInput testID="ssnId" placeHolder={configJSON.placeaHolderSSN} value={this.state.ssnValue} handleInput={this.handleSsn} errorMsg={!this.state.isValidateSsn && configJSON.errorSsn} valid={this.state.isValidateSsn} />
          </>
        }
      </>
    )

  }

  renderTpaBenefitAdministrator = () => {
    return (
      <>
        {
          this.checkUserType() &&
          <>
            {
              this.commonInputs()
            }
            {
              this.companyFacilityTaxIdInputs()
            }
          </>
        }
      </>
    )
  }

  renderHealthCareProvider = () => {
    return (
      <>
        {
          this.state.userType === "Healthcare Provider (Doctor Office)" &&
          <>
            {
              this.commonInputs()
            }
            <CustomInput testID="hospital" placeHolder={configJSON.placeHolderHospital} value={this.state.hospitalOrPatientName} handleInput={this.handleHospitalOrPatientName} errorMsg={!this.state.isValidateHospitalOrPatientName && configJSON.errorHospitalOrPatientName} valid={this.state.isValidateHospitalOrPatientName} />
            <CustomInput testID="taxId" placeHolder={configJSON.PlaceHolderTaxId} value={this.state.taxId} handleInput={this.handleTaxId} errorMsg={!this.state.isValidateTaxId && configJSON.errorTaxId} valid={this.state.isValidateTaxId} />
          </>

        }
      </>
    )
  }

  renderCostContainment = () => {
    return (
      <>
        {
          this.state.userType === "Cost Containment (Nurse)" &&
          <>
            {
              this.commonInputs()
            }
            <CustomInput testID="companyName" placeHolder={configJSON.placeHolderCompanyName} value={this.state.companyName} handleInput={this.handleCompanyName} errorMsg={!this.state.isValidateCompanyName && configJSON.errorCompanyName} valid={this.state.isValidateCompanyName} />
            <CustomInput testID="taxId" placeHolder={configJSON.PlaceHolderTaxId} value={this.state.taxId} handleInput={this.handleTaxId} errorMsg={!this.state.isValidateTaxId && configJSON.errorTaxId} valid={this.state.isValidateTaxId} />
          </>
        }
      </>
    )
  }
  // Customizable Area End

  render() {
    return (
      <KeyboardAvoidingView
        behavior={this.isPlatformiOS() ? "padding" : undefined}
        style={styles.keyboardPadding}
      >
        <ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
          <TouchableWithoutFeedback
            testID={"Background"}
            onPress={() => {
              this.hideKeyboard();
            }}
          >
            {/* Customizable Area Start */}
            <SafeAreaView style={styles.mainContainer}>
              <StatusBar barStyle="dark-content" backgroundColor={this.state.modalVisible?"rgba(0,0,0,0.2)":"#ffffff"} />
              <View style={styles.headerContainer}>
                <TouchableOpacity style={styles.backButton}
                testID="backButton"
                  onPress={() => {
                    this.props.navigation.goBack();
                  }}
                >
                  <Arrowleft
                    testID="arrowLeftButton"
                    name="arrow-left"
                    size={verticalScale(28)}
                    color={"#4166f8"}

                  />
                </TouchableOpacity>

              </View>
              <Text 
              style={styles.createAccountTitle}>
                {configJSON.createAccountTitle}</Text>
              <Text 
              style={styles.createAccountDes}>
                {configJSON.createAccountDes}</Text>

              <TouchableOpacity
                testID="getUserListBtn"
                style={styles.dropDownContainer} onPress={() => this.getUserTypeList()}>
                <Text 
                style={[styles.dropdownText, { fontWeight: this.state.userType !==  "I am" && this.state.userType ? '600' : '400', color: this.state.userType !== "I am" && this.state.userType? "#000000" : '#7a7b7f' }]}>
                  {this.state.userType ? this.state.userType : "I am"}</Text>
                <Icon
                  testID="downArrowBtn"
                  name="chevron-down" size={verticalScale(20)} color={"#000000"} onPress={() => this.getUserTypeList()} />
              </TouchableOpacity>

              {
                this.state.openDropDown &&
                  <>
                    <ScrollView scrollEnabled={true} style={styles.usersListContainer} >

                      {
                        this.state.usersListArray.map((item: any, index: number) => {
                          return (
                              <TouchableOpacity
                              testID={`handleDropdownBtn${index}`}
                              style={styles.listItem} onPress={() => this.handleDropDown(item.name, index, item.id)}>
                              <Text >{item.name}</Text>
                            </TouchableOpacity>
                          )
                        })
                      }
                    </ScrollView>
                  </>      
              }
              {
                this.renderEmployerHr()
              }
              {
                this.renderEmployeeMember()
              }
              {
                this.renderTpaBenefitAdministrator()
              }
              {
                this.renderHealthCareProvider()
              }
              {
                this.renderCostContainment()
              }
              {
                this.state.userType !== "I am" && this.state.userType ?
                <TouchableOpacity
                  testID="registerBtn8"
                  style={styles.registerButton} onPress={() => this.handleRegister()}>
                  <Text style={styles.registerButtonText}>{configJSON.labelRegisterButton}</Text>
                </TouchableOpacity>
                :
                <></>
              }
            <CustomLoader testID = "loaderComponent" modalVisible = {this.state.modalVisible} modalClose = {this.handleModalClose}/>
            </SafeAreaView>
            {/* Customizable Area End */}
          </TouchableWithoutFeedback>
        </ScrollView>
      </KeyboardAvoidingView>
    );
  }

  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }
}

const styles = StyleSheet.create({
  // Customizable Area Start
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    maxWidth: 650,
    backgroundColor: "#ffffff",


  },
  titleWhySignUp: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10
  },

  inputWeb: {
    flex: 1,
    flexDirection: "row",
    marginTop: 24,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    includeFontPadding: true,
    borderBottomWidth: 1
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10
  },
  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true,
    marginTop: 10,
    paddingLeft: 0
  },
  passwordShowHide: {
    alignSelf: "center"
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderWidth: 1,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
    zIndex: -1
  },
  imgPasswordShowhide: {},
  keyboardPadding: { flex: 1 },
  btnLegalTermsAndCondition: { color: "#6200EE" },
  btnLegalPrivacyPolicy: { color: "#6200EE", marginLeft: "auto" },
  leagalText: { marginTop: 10 },
  headline: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },
  signUpText: {
    fontSize: 32,
    color: "#6200EE",
    fontWeight: "bold"
  },
  mainContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: "center"
  },
  createAccountTitle: {
    fontSize: verticalScale(33),
    color: "#212330",
    fontWeight: "400",
    marginTop: verticalScale(25)

  },
  createAccountDes: {
    fontSize: verticalScale(14),
    color: "#3c4557",
    marginTop: verticalScale(10),
    fontWeight: "400",
    textAlign: "center",
  },
  dropDownContainer: {
    height: heightPercentageToDP(6.5),
    width: widthPercentageToDP(90),
    backgroundColor: '#f4f6fb',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: verticalScale(15),
    marginTop: verticalScale(80),
    borderRadius: verticalScale(15),

  },
  dropdownText: {
    fontSize: verticalScale(14),

  },
  usersListContainer: {  
    width: widthPercentageToDP(85),
    marginTop: verticalScale(10),
    backgroundColor: '#ffffff',
    shadowColor: "rgba(117,132,165,0.9)",
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 1,
    shadowRadius: 3,
    elevation: 8,
    marginBottom: verticalScale(20),
    alignSelf: 'center',
  },
  listItem: {
    height: heightPercentageToDP(4),
    width: widthPercentageToDP(80),
    marginBottom: verticalScale(10),
    padding: verticalScale(5),
    margin:verticalScale(6)
  },
  registerButton: {
    height: heightPercentageToDP(6.5),
    width: widthPercentageToDP(90),
    backgroundColor: '#4166f8',
    marginTop: verticalScale(80),
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: verticalScale(15),
    alignSelf: 'center',
    marginBottom: verticalScale(30)
  },
  emailErrorMessage: {

    alignSelf: 'flex-start',
    paddingLeft: verticalScale(10),
    fontSize: verticalScale(10),
  },
  registerButtonText: {
    fontSize: verticalScale(18),
    color: '#fff',
    fontWeight: '400',
    alignSelf: 'center',

  },
  headerContainer: {
    height: heightPercentageToDP(8),
    width: widthPercentageToDP(90),
    justifyContent: 'flex-start',
    alignSelf: 'center'
  },
  backButton: {
    height: heightPercentageToDP(6.5),
    width: widthPercentageToDP(13),
    borderRadius: verticalScale(25),
    backgroundColor: '#eceef1',
    justifyContent: 'center',
    alignItems: 'center',

  },
  errorMsg: {
    paddingLeft: verticalScale(5),
    fontSize: verticalScale(10),
    color: "#ff0000",
    fontWeight: "400",
    marginTop: verticalScale(5)
}
  // Customizable Area End
});

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import {Alert} from "react-native"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  address: string;
  email: string;
  password: string;
  confirmPassword: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  openDropDown: boolean;
  userType: string;
  showHidePassword: boolean;
  showHideConfirmPassword: boolean;
  companyFacilityName: string;
  taxId: string;
  ssnValue: string;
  companyName: string;
  hospitalOrPatientName: string;
  isValidEmail?: boolean
  isValidPassword: boolean;
  isValidConfirmPassword: boolean;
  isValidatePhoneNumber: boolean;
  isValidateFname: boolean,
  isValidateLname: boolean,
  isValidateAddress: boolean,
  isValidateCompanyName: boolean,
  isValidateTaxId: boolean,
  isValidateSsn: boolean,
  isValidateHospitalOrPatientName: boolean,
  isValidateCompanyFacilityName: boolean,
  usersListArray: object[],
  userId: number,
  modalVisible:boolean,
  emailErrMessage:string,
  mobileErrMessage:string
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: string = "";
  validationApiCallId: string = "";
  getUserTypesListApiCallId: string = "";
  apiSignupcallId: string = "";

  imgPasswordVisible: boolean;
  imgPasswordInVisible: boolean;

  labelHeader: string;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      openDropDown: false,
      userType: '',
      address: "",
      confirmPassword: '',
      showHidePassword: true,
      showHideConfirmPassword: true,
      companyFacilityName: "",
      taxId: "",
      ssnValue: "",
      companyName: "",
      hospitalOrPatientName: "",
      isValidEmail: false,
      isValidPassword: false,
      isValidConfirmPassword: false,
      isValidatePhoneNumber: false,
      isValidateFname: false,
      isValidateLname: false,
      isValidateAddress: false,
      isValidateCompanyName: false,
      isValidateTaxId: false,
      isValidateSsn: false,
      isValidateHospitalOrPatientName: false,
      isValidateCompanyFacilityName: false,
      usersListArray: [],
      userId: 1,
      modalVisible:false,
      emailErrMessage:"",
      mobileErrMessage:""
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/);
    this.emailReg = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      // getUserTypesListApiCallId
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getUserTypesListApiCallId) {
          console.log(responseJson?.data, "responsejbfuibrubvu")
          this.setState({ usersListArray: responseJson?.data, modalVisible:false });
        }
        this.handleSignupApiCall(apiRequestCallId,responseJson)
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  handleSignupApiCall = (apiRequestCallId:string,responseJson:{meta:{token:string}, errors:string}) =>{
    if (apiRequestCallId === this.apiSignupcallId){
    if (responseJson.meta && responseJson.meta.token) {
      this.setState({ modalVisible: false ,isValidEmail:true, emailErrMessage:"", mobileErrMessage:"", isValidatePhoneNumber:true})
      Alert.alert(
        configJSON.successTitle,
        configJSON.userCreatedMsg,
        [
          { text: 'OK', onPress: () => 
            this.props.navigation.navigate("EmailAccountLoginBlock")
           },
        ]
      );
    } else {
      this.setState({modalVisible:false},() =>{
        console.log(responseJson?.errors, "guguiegiugfiegiufg")
        responseJson?.errors && String(responseJson?.errors).includes("email")&& responseJson?.errors &&
        this.setState({emailErrMessage: responseJson?.errors, isValidEmail:false})
        responseJson?.errors && String(responseJson?.errors).includes("Mobile")&& responseJson?.errors &&
        this.setState({mobileErrMessage: responseJson?.errors, isValidatePhoneNumber:false})
      })
    }

    }
  }


  handleDropDown = (name: string, index: number, id: number) => {
    this.setState({ openDropDown: false, userId: id, userType: name, hospitalOrPatientName: '', email: "", password: '', phone: '', companyFacilityName: '', companyName: '', confirmPassword: "", ssnValue: '', taxId: '', });


  }
  handleFirstName = (value: string) => {
 
    this.setState({ firstName: value, isValidateFname: this.validateDetails(value) })
  }
  handleLastName = (value: string) => {
    this.setState({ lastName: value, isValidateLname: this.validateDetails(value) })
  }
  handleAddress = (value: string) => {
    this.setState({ address: value, isValidateAddress: this.validateDetails(value) })
  }
  handlePhoneNumber = (value: string) => {
    this.setState({ phone: value }, () => {
      this.setState({ isValidatePhoneNumber: this.validatePhoneNumber(value) , mobileErrMessage:""})
    })
  }
  handleEmail = (value: string) => {
    this.setState({ email: value, isValidEmail: this.validateEmail(value) , emailErrMessage:""})
  }
  handlePassword = (value: string) => {
    this.setState({ password: value, isValidPassword: this.validatePassword(value) })
  }
  showHidePassword = () => {

    this.setState({ showHidePassword: !this.state.showHidePassword })
  }
  handleModalClose = () =>{
    this.setState({modalVisible:!this.state.modalVisible})
  }
  checkUserType = () =>{
    if(this.state.userType === "Client/ TPA/ Benefit Administrator" || this.state.userType === "Broker/Agent" || this.state.userType ==="Stop Loss Carrier"){
      return true
    }else{
      return false
    }
  }
  handleConfirmPassword = (value: string) => {


    this.setState({ confirmPassword: value }, () => {
      this.setState({ isValidConfirmPassword: this.validateConfirmPassword() })
    })


  }
  showHideConfirmPassword = () => {
    this.setState({ showHideConfirmPassword: !this.state.showHideConfirmPassword })
  }
  handleCompanyFacilityName = (value: string) => {
    this.setState({ companyFacilityName: value, isValidateCompanyFacilityName: this.validateDetails(value) });

  }
  handleTaxId = (value: string) => {
    this.setState({ taxId: value, isValidateTaxId: this.validateDetails(value) });

  }
  handleSsn = (value: string) => {
    this.setState({ ssnValue: value, isValidateSsn: this.validateDetails(value) });

  }
  handleCompanyName = (value: string) => {
    this.setState({ companyName: value, isValidateCompanyName: this.validateDetails(value) });

  }
  handleHospitalOrPatientName = (value: string) => {
    this.setState({ hospitalOrPatientName: value, isValidateHospitalOrPatientName: this.validateDetails(value) });

  }

  validateEmail = (email: string) => {
    if (email.length > 0) {

      return this.emailReg.test(email);
    }

  };
  validatePassword = (password: string) => {

    return this.passwordReg.test(password);
  };
  validateConfirmPassword = () => {
   
    if (this.state.confirmPassword == this.state.password) {
      return true;
    }
    return false;

  }
  validatePhoneNumber = (value: string) => {
    const mobileRegex = /^(?:\+91|0)?[789]\d{9}$/;
    const usaRegex = /^\d{10}$/;
    if (mobileRegex.test(value) || usaRegex.test(value)) {
      return true;
    } 
    else{
      return false;
    }
  };

  validateDetails = (value: string) => {
    if (value.length > 1) {
      return true;
    }
    return false;
  }

  registerRole = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };
    const data =
    {
      "type": "email_account",
      "attributes": {
        "first_name": this.state.firstName ? this.state.firstName : null,
        "last_name": this.state.lastName ? this.state.lastName : null,
        "address": this.state.address ? this.state.address : null,
        "email": this.state.email ? this.state.email : null,
        "password": this.state.password ? this.state.password : null,
        "confirm_password": this.state.confirmPassword ? this.state.confirmPassword : null,
        "full_phone_number": this.state.phone.startsWith("+91") ? this.state.phone : `1${this.state.phone}`,
        "company": this.state.companyName ? this.state.companyName : this.state.companyFacilityName,
        "tax_id": this.state.taxId ? this.state.taxId : null,
        "role_id": this.state.userId,
        "ssn": this.state.ssnValue ? this.state.ssnValue : null,
        "hospital_name": this.state.hospitalOrPatientName ? this.state.hospitalOrPatientName : null,


      }
    }
    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiSignupcallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signupEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      (header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    console.log("reufbuyebu", requestMessage)
 

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  checkEmployerHr = () =>{
    if (this.state.firstName && this.state.lastName && this.state.address && this.state.companyFacilityName && this.state.taxId) {
      this.registerRole()
    } else {
      this.setState({modalVisible:false},() =>{
      this.showAlert("Error", "Plese fill the required details")

      })
    }
  }
  checkEmployeeMember = () =>{
    if (this.state.companyFacilityName && this.state.ssnValue) {
      this.registerRole()
    } else {
      this.setState({modalVisible:false},() =>{
        this.showAlert("Error", "Plese fill the required companyFacilityName or SSN")
      })
    }
  }
  checkCostContainment = () =>{
    if (this.state.companyName && this.state.taxId) {
      this.registerRole()
    } else {
      this.setState({modalVisible:false},() =>{ 
        this.showAlert("Error", "Plese fill the required companyName or taxId")
      })
    }
  }
  checkHealthCareProvider = () =>{
    if (this.state.hospitalOrPatientName && this.state.taxId) {
      this.registerRole()
    } else {
      this.setState({modalVisible:false},() =>{
        this.showAlert("Error", "Plese fill the required hospitalOrPatientName or taxId")
      })
    }
  }
  checkStopLossCarrier = () =>{
    if (this.state.companyFacilityName && this.state.taxId) {
      this.registerRole()
    } else {
      this.setState({modalVisible:false},() =>{
        this.showAlert("Error", "Plese fill the required companyFacilityName or taxId")
      })
    }
  }
  handleUserTypeRegister = () =>{
    switch (this.state.userType) {
      case "Employer (HR)":
        this.checkEmployerHr()
        break;
      case "Employee (Member)":
        this.checkEmployeeMember()
        break;
      case "Cost Containment (Nurse)":
        this.checkCostContainment()
        break;
      case "Healthcare Provider (Doctor Office)":
       this.checkHealthCareProvider()
        break;
      case "Client/ TPA/ Benefit Administrator":
      case "Broker/Agent":
      case "Stop Loss Carrier":
       this.checkStopLossCarrier()
        break;
      default:
        break;
    }
  }

  handleRegister = () => {
    this.setState({modalVisible:true},() =>{
      if (this.state.email && this.state.isValidEmail
        && this.state.password && this.state.isValidPassword
        && this.state.confirmPassword && this.state.isValidConfirmPassword
        && this.state.phone && this.state.isValidatePhoneNumber) {
          this.handleUserTypeRegister()
      } else {
        this.setState({modalVisible:false},() =>{
        this.showAlert("Error", "Please enter valid details")
        })
      }
    })
  }


  getUserTypeList = () => {
    this.setState({modalVisible:true, openDropDown: !this.state.openDropDown, userType:"I am", emailErrMessage:"", mobileErrMessage:""},() =>{
      const getUserTypesListMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getUserTypesListApiCallId = getUserTypesListMsg.messageId;
      getUserTypesListMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getUsersTypeListEndPoint
      );
  
      getUserTypesListMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      runEngine.sendMessage(getUserTypesListMsg.id, getUserTypesListMsg);
    })
  
  }
  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }
  // Customizable Area End
}

Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email address";
exports.placeHolderPassword = "Password";
exports.placeHolderConfirmPassword = "Confirm Password";
exports.placeHolderCompanyFacility = "Company/Facility Name"
exports.PlaceHolderTaxId = "Tax id"
exports.labelRegisterButton = "Register"
exports.placeaHolderSSN = "SSN"
exports.placeHolderCompanyName = "Company Name"
exports.placeHolderHospital = "Hospital or Practice Name"
exports.warningInfo="*At least 8 characters with least 1 uppercase, 1 lowercase 1 number and 1 special character."
exports.confirmPasswordInfo  = "*Password mismatch. Please try again"
exports.errorMsgPnoneNumber = "Please enter a valid mobile number"
exports.errorFname = "Please enter a valid first name"
exports.errorLname = "Please enter a valid last Name"
exports.errorAddress = "Please enter a valid address"
exports.errorCompanyName = "Please enter a company name"
exports.errorTaxId = "Please enter a valid tax identifier"
exports.errorSsn = "Please enter a valid ssn"
exports.errorHospitalOrPatientName = "Please enter a valid hospital or patient name"
exports.errorCompanyFacilityName = "Please enter a valid company facility name"


exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.address = "Address";
exports.phoneNumber = "Phone number (Mobile)";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Enter a valid email.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";
exports.signupEndpoint = "/account_block/accounts"

exports.validationApiMethodType = "GET";
exports.createAccountTitle = "Create an Account";
exports.createAccountDes = " Please select your role and complete the sections to Create your account.";
exports.getUsersTypeListEndPoint = "/bx_block_roles_permissions/get_all_roles"
exports.successTitle = "Success"
exports.userCreatedMsg = "Account registered successfully."

// Customizable Area End

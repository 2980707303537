Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Enter a valid email.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email address";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log In";
exports.warningInfo="*At least 8 characters with least 1 uppercase, 1 lowercase 1 number and 1 special character."
exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.loginDescription = "Please enter your details to Login into your account.";
exports.emailInputPlaceholder = "Email address";
exports.rememberMeText = "Remember me";
exports.forgotPasswordText = "Forgot Password";
exports.createAccountText1 = "Don't have an account yet?";
exports.createAccountText2 = " Create an account";
exports.loginApiEndpoint = "/bx_block_login/login";
exports.errorTitle = "Error";
exports.successTitle = "Success";
exports.loginSuccessMsg = "Login completed successfully";
exports.emailvalidationErrorMsg = "Please enter your email or password";
exports.emailAccountType = "email_account";
// Customizable Area End
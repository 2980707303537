import React, { Component } from 'react';
import { Modal, View, ActivityIndicator, Text , StyleSheet} from 'react-native';
import {
    verticalScale,
    widthPercentageToDP,
    heightPercentageToDP,
  } from "../src/Scale";
import Icon from "react-native-vector-icons/FontAwesome"
interface Props{
    modalVisible:boolean;
    modalClose:() => void;
    testID?:string;
}

class CustomLoader extends Component<any, Props>{

    handleClose = () =>{
        this.props.modalClose()
    }
    render() {
        return(
            <Modal
                animationType="none"
                transparent={true}
                visible={this.props.modalVisible}
                onRequestClose={() => {
                    this.handleClose()
                }}
            >
                <View style={styles.icon_container}>
                    <Icon
                        onPress={() => this.handleClose()}
                        name="close"
                        size={verticalScale(20)}
                        color="black" />
                </View>
                <View style={styles.bottom_container}>
                    <View style={styles.loader_container}>
                        <ActivityIndicator size="large" color="#4166F8" animating={this.props.modalVisible} />
                    </View>
                </View>
            </Modal>
        )
    }
}


const styles = StyleSheet.create({
    icon_container:{
        padding: 20,
        backgroundColor: 'rgba(0,0,0,0.2)',
        height: heightPercentageToDP(9),
        width: widthPercentageToDP(100),
        justifyContent: 'flex-start',
        alignItems: 'flex-end'
    },
    bottom_container:{
        backgroundColor: 'rgba(0,0,0,0.2)',
        height: heightPercentageToDP(91),
        width: widthPercentageToDP(100),
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: 'center', 
    },
    loader_container:{
        height: heightPercentageToDP(8),
        width: widthPercentageToDP(16),
        backgroundColor: "#ffffff",
        borderRadius: verticalScale(10),
        justifyContent: 'center',
        alignItems: 'center'
    }

})

export default CustomLoader;